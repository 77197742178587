import React from 'react';
import {connect} from "react-redux";

import AppLayout from "../components/AppLayout";
import InvokeDisbursementPanel from "../components/InvokeDisbursementPanel";
import PaymentsPanel from "../components/PaymentsPanel";
import InvokeOriginationPanel from "../components/InvokeOriginationPanel";
import {submitIpcRequest} from "../store/actions/consoleActions";
import BoardingFilesPanel from "../components/BoardingFilesPanel";
import GenerateReportPanel from "../components/GenerateReportPanel";

function PaymentsPage(props) {
    return (
        <AppLayout title="Payments">
            <InvokeDisbursementPanel submitRequest={props.submitIpcRequest}/>
            <PaymentsPanel submitRequest={props.submitIpcRequest}/>
            <InvokeOriginationPanel submitRequest={props.submitIpcRequest}/>
            <BoardingFilesPanel submitRequest={props.submitIpcRequest}/>
            <GenerateReportPanel submitRequest={props.submitIpcRequest}/>
        </AppLayout>
    )
}

export default connect(null, {submitIpcRequest})(
    PaymentsPage
);
