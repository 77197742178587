import React from 'react';
import { connect } from "react-redux";

import AppLayout from "../components/AppLayout";

function MessageTemplatesPage() {
    return (
        <AppLayout title="MessageTemplates">
        </AppLayout>
    )
}

export default connect(null, {})(
    MessageTemplatesPage
);
